import styles from './card.module.scss';
import classNames from 'classnames';
import video1 from '../../assets/videos/Poradne_me_vymet.MOV'
import image1 from '../../../src/assets/CardImage1.jpg';



export interface CardProps {
    className?: string;
    imagePreview?: string;
    title: string;
    name: string;
    videoPreview?: string;
    isVideo: boolean;
}

function alertOnClick(){
    alert('Tohle je jenom maketa. Skutečné video se musí natočit, sestříhat a nahrát :))');
 }

export const Card = ({ className, imagePreview, title, name, videoPreview, isVideo }: CardProps) => {
    return (
        <div className={classNames(styles.root, className)} onClick={alertOnClick}>
                    <svg className={styles.playButton}>
                        <circle className={styles.playButtonCircle}r="40%" cx="50%" cy="50%"/>
                        <polygon className={styles.playButtonTriangle} points='30,20 60,40 30,60'/>
                    </svg>
            {isVideo? 
                <video width="250" height="200" controls  >
                    <source src={video1}/>
                </video>
                :
                <img src={imagePreview} />
            }
            <h3>{title}</h3>
            <p>{name}</p>
            <p>{`${Math.floor(Math.random() * 500) + 5}k views - ${Math.floor(Math.random() * 25) + 1} days ago`}</p>
        </div>
    );
};
