import styles from './content-container.module.scss';
import classNames from 'classnames';
import { BackButton } from '../back-button/back-button';
import { ContentGrid } from '../content-grid/content-grid';
import { Content } from '../content/content';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../redux/rootReducer';

export interface ContentContainerProps {
    className?: string;
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/configuration-for-neon-borders-and-templates
 */
export const ContentContainer = ({ className }: ContentContainerProps) => {

    const isVisible = useSelector((state: RootState) => state.content.isVisible);
    return (
        <div className={classNames(styles.root, className)}>
            <ContentGrid />
            <Content />
            <BackButton />
        </div>
    );
};
