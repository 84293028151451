import { combineReducers } from '@reduxjs/toolkit';
import contentReducer from './contentSlice';

const rootReducer = combineReducers({
    content: contentReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
