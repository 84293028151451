import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ContentState {
    isVisible: boolean;
    article: number
}

const initialState: ContentState = {
    isVisible: false,
    article: 2
};

const contentSlice = createSlice({
    name: 'content',
    initialState,
    reducers: {
        setContentVisibility: (state, action: PayloadAction<boolean>) => {
            state.isVisible = action.payload;

        },
        setArticle: (state, action: PayloadAction<number>) => {
            state.article = action.payload
        }
    },
});


export const { setContentVisibility, setArticle } = contentSlice.actions;
export default contentSlice.reducer;
