import styles from './nav.module.scss';
import classNames from 'classnames';
import { Content } from '../content/content';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import { setArticle, setContentVisibility } from '../../redux/contentSlice';

export interface NavProps {
    className?: string;
}

export const Nav = ({ className }: NavProps) => {
    const dispatch = useDispatch();

    const handleClickOHre = () => {
        dispatch(setContentVisibility(true));
        dispatch(setArticle(0))
    };
    const handleClickRole = () => {
        dispatch(setContentVisibility(true));
        dispatch(setArticle(1))
    };
    const handleClickONas = () => {
        dispatch(setContentVisibility(true));
        dispatch(setArticle(2))
    };

    const navArray = ['O hře', 'Role', 'O nás'];

    return (
        <div className={classNames(styles.root, className)}>
            <nav className={classNames(styles.root, 'neon-effect-border')}>
                    <button className="neon-effect-border" onClick={handleClickOHre}>{navArray[0]}</button>
                    <button className="neon-effect-border" onClick={handleClickRole}>{navArray[1]}</button>
                    <button className="neon-effect-border" onClick={handleClickONas}>{navArray[2]}</button>
            </nav>
        </div>
    );
};
