import styles from './hand-left.module.scss';
import classNames from 'classnames';
import { useState, useEffect } from 'react';
import handImage from '../../../src/assets/Hand-left.png';

export interface HandLeftProps {
    className?: string;
}



export const HandLeft = ({ className }: HandLeftProps) => {

    const [rotationAngle, setRotationAngle] = useState(0);

    useEffect(() => {
        const handLeft = document.querySelector(`.${styles.root}`) as HTMLElement;
        const handleMouseMove = (e: MouseEvent) => {
            const handLeftRect = handLeft.getBoundingClientRect();
            const centerX = handLeftRect.left + handLeftRect.width / 2;
            const centerY = handLeftRect.top + handLeftRect.height / 2;
            let x = e.clientX - centerX;
            let y = e.clientY - centerY;
            if (x<250) x = 250
            if (y<-250) y = -250
            const angle = Math.atan2(y, x) * (180 / Math.PI) +20;
            setRotationAngle(angle);
        };

        window.addEventListener('mousemove', handleMouseMove);
        return () => window.removeEventListener('mousemove', handleMouseMove);
    }, []);

    
    
    return (
        <div className={classNames(styles.root, styles.left, className)} 
            style={{ transform: `rotate(${rotationAngle}deg)`}}
>
            <img src={handImage} alt='' />
        </div>
    );
};
