import styles from './content.module.scss';
import classNames from 'classnames';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import { setContentVisibility } from '../../redux/contentSlice';

export interface ContentProps {
    className?: string;
}

const ArticleOHre = () => {

    return (
        <>
        <h1>O hře</h1>
        <div className={styles['paragraph-text']}>
            <p>
                Kdysi dávno za několika kopci napsal autorský tandem Kohlaase a&nbsp;Zimmer divadelní hru <em>Ryba ve čtyřech.</em> Po spoustě desítek let přinášíme naší vlastní variaci této hry jako svědectví toho, kam se svět a&nbsp;tedy i&nbsp;my mentálně posul za těch několik válek a&nbsp;chvil míru. Pokud nabydete pocitu, že jsme na tom hůř, pravděpodobně na tom hůř jsme. Budete-li z&nbsp;divadla odcházet přesvědčeni o&nbsp;tom, že žijeme v&nbsp;lepším světě, pravděpodobně v&nbsp;něm žijeme.
            </p>
            <h3>Mortiát zůstal mortiátem</h3>
            <p>
                Původní divadelní kus jsme pečlivě pročetli a&nbsp;věnovali jsme také hodně času studiu jeho různých inscenací. Významné pro nás bylo televizní zpracování a&nbsp;výborné divadelní ztvárnění divadle DS Monde. Zachovali jsme původní rozložení celé hry, zachovali jsme i&nbsp;čtyři hlavní postavy, zachovali jsme jejich příbuzenské vztahy, jen dva tuláky nahradil virtuální prostor, který svou on-line formou zasahuje do našich běžných životů stejně, jako kdysi zasahovali do životů maloměšťáků potulní cikáni. Nebojte se nás, smrt se často přeceňuje.
            </p>
        </div>x
        </>
    )
}

const ArticleRole = () => {
    return (
        <>
        <h1>Role</h1>
        <div className={styles['list']}>
            <ul>
                <li><h2>Rudolf</h2><div><h5>:</h5></div><h3>Jan Donáth</h3></li>
                <li><h2>Tina</h2><div><h5>:</h5></div><h3>Lucie Štolcová</h3></li>
                <li><h2>Cecílie</h2><div><h5>:</h5></div><h3>Jana Bezemková</h3></li>
                <li><h2>Karolína</h2><div><h5>:</h5></div><h3>Petra Benešová</h3></li>
                <div className={styles['production']}>
                    <li><h4>režie</h4><div><h5>:</h5></div><h3>Pepe Šedivý</h3></li>
                    <li><h4>kostýmy, produkce</h4><div><h5>:</h5></div><h3>Lucie Štolcová</h3></li>
                </div>
            </ul>
        </div>
        </>
    )
}

const ArticleONas = ({ className }: ContentProps ) => {
    return (
        <>
        <h1>Nepřijatelní</h1>
        <div className={styles['paragraph-text']}>
            <p>
            Nepřijatelní jsou seskupením kreativních osobností z&nbsp;nejrůznějších oborů a&nbsp;škol - ve svých řadách "shromažďují" tvůrčí osobnosti z&nbsp;nejrůznějších oborů (od výtvarníků, divadelníků až po grafiky a&nbsp;animátory) a&nbsp;škol (Univerzita Karlova, filmová škola v&nbsp;Písku, JAMU).
            </p>
            <h3>Náš cíl</h3>
            <p>
            Nepřijatelní si dali za cíl podporu amatérských umělců, neúspěšných adeptů na studium uměleckých škol a&nbsp;jejich studentů či absolventů za účelem jejich dalšího uměleckého rozvoje. Příprava, organizace a&nbsp;podpora jejich neprofesionálních divadelních, hudebních, tanečních, výtvarných a&nbsp;jiných kulturních projektů a&nbsp;veřejných vystoupení (divadelní představení, koncerty, vernisáže, výstavy atd.).
            </p>
            <h3>Úplná historie</h3>
            <p>
            Nepřijatelní se vyvinuli z&nbsp;tříletého soužití divadelního spolku "Uvidíme…", aby dále "oficiálně" rozvíjeli své tvůrčí snahy. Ještě z&nbsp;dob spolku "Uvidíme…" máme za sebou množství zajímavých projektů – od divadelních inscenací nejrůznějších autorů (W.Allen, N.Erdman, J.Suchý), přes mezinárodní divadelní spolupráci (projekt Babylon s&nbsp;řeckou divadelní skupinou), až po natáčení drobných filmů (Absolvent, Terapie, trailer k&nbsp;inscenaci Sebevrah). V současnosti se spolek zabývá mezinárodními projekty (Čarodějův učeň, Wodny muž), sociálními projekty (Dámská šatna), literárními projekty (Literární dílny) a&nbsp;především dramaturgií současných nepříliš mainstreamových textů (Podivný pár, Jedlíci čokolády, Mezi druhým a&nbsp;třetím, Dealeři, Tlustý prase, atd). Více informací a&nbsp;celý repertoár najdete na <a href='http://www.neprijatelni.cz/' target='blank'>našem divadelním webu</a>
            </p>
        </div>
        </>
    )
}
export const articles = [<ArticleOHre/>, <ArticleRole/>, <ArticleONas/>]

export const Content = ({ className }: ContentProps) => {
    const isVisible = useSelector((state: RootState) => state.content.isVisible);
    const article = useSelector((store: RootState) => store.content.article)
    const dispatch = useDispatch();

    return (
        <div className={classNames(styles.root, { [styles.visible]: isVisible })}>
            <div>
                {articles[article]}
            </div>
        </div>
    );
};
