import styles from './logo.module.scss';
import classNames from 'classnames';

export interface LogoProps {
    className?: string;
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/configuration-for-logos-and-templates
 */
export const Logo = ({ className }: LogoProps) => {
    return (
        <div className={classNames(styles.root, className)}>
            <div className={styles['container']}>
                <h2 className={styles['logo-holky']}>Holky</h2>
                <h2 className={styles['logo-poradny']}>Pořádný</h2>
            </div>
        </div>
    );
};
