import styles from './hero-image.module.scss';
import classNames from 'classnames';
import heroImage from '../../assets/babe2.png';
import { PulsingHeart } from '../pulsing-heart/pulsing-heart';

export interface HeroImageProps {
    className?: string;
}

export const HeroImage = ({ className }: HeroImageProps) => {
    return (
        <div className={styles.root}>
            <PulsingHeart />
            <img src={heroImage} alt="" className={styles['babe']} />
        </div>
    );
};
