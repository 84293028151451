import background from './assets/BackgroundImage.webp';
import styles from './App.module.scss';
import { ContentContainer } from './components/content-container/content-container';
import { NeonBorder } from './components/neon-border/neon-border';
import { Provider } from 'react-redux';
import store from './redux/store';
import { HandLeft } from './components/hand-left/hand-left';
import { HandRight } from './components/hand-right/hand-right';

function App() {
    return (
        <div className={styles.App}>
            <header className={styles['App-header']}>
                <Provider store={store}>
                <img src={background} alt="" className={styles.background} />
                    <NeonBorder />
                    <ContentContainer />
                    <HandLeft />
                    <HandRight />
                </Provider>
            </header>
        </div>
    );
}

export default App;
