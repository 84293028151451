import styles from './neon-border.module.scss';
import classNames from 'classnames';
import { ContentGrid } from '../content-grid/content-grid';

export interface NeonBorderProps {
    className?: string;
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/configuration-for-neon-borders-and-templates
 */
export const NeonBorder = ({ className }: NeonBorderProps) => {
    return (
        <div className={classNames(styles.root, className, 'neon-effect-border')}>
            
        </div>
    );
};
