import styles from './content-grid.module.scss';
import classNames from 'classnames';
import { Nav } from '../nav/nav';
import { Card } from '../card/card';
import image1 from '../../assets/CardImage1.jpg';
import image2 from '../../assets/CardImage2.jpg';
import image3 from '../../assets/CardImage3.jpg';
import image4 from '../../assets/CardImage4.jpg';
import image5 from '../../assets/CardImage5.jpg';
import video1 from '../../assets/videos/Poradne_me_vymet.MOV'

import { HeroContainer } from '../hero-container/hero-container';

export interface ContentGridProps {
    className?: string;
}

/**
 * This component was created using Codux's Default new component template.
 * To create custom component templates, see https://help.codux.com/kb/en/article/configuration-for-content-grids-and-templates
 */
export const ContentGrid = ({ className }: ContentGridProps) => {
    return (
        <div className={classNames(styles.root, className)}>
            <div className={styles.grid}>
                <Nav />
                <Card isVideo={false} imagePreview={image1} title={'Pořádně mě vymeť'} name={'Cilka'} />
                <Card isVideo={false} imagePreview={image2} title={'Vyluxuju tě'} name={'Tína'} />
                <Card isVideo={false} imagePreview={image3} title={'Chceš mě opucovat?'} name={'Cilka'} />
                <Card isVideo={false} imagePreview={image4} title={'Vytřu ti zrak'} name={'Tína'} />
                <HeroContainer />
                <Card isVideo={false} imagePreview={image5} title={'Umeju ti nádobíčko'} name={'Cilka'} />
            </div>
        </div>
    );
};


