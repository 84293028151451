import styles from './hand-right.module.scss';
import classNames from 'classnames';
import { useState, useEffect } from 'react';
import handImage from '../../../src/assets/Hand-right.png';

export interface HandRightProps {
    className?: string;
}

export const HandRight = ({ className }: HandRightProps) => {
    const [rotationAngle, setRotationAngle] = useState(0);

    useEffect(() => {
        const handRight = document.querySelector(`.${styles.root}`) as HTMLElement;
        const handleMouseMove = (e: MouseEvent) => {
            const handRightRect = handRight.getBoundingClientRect();
            const centerX = handRightRect.left + handRightRect.width / 2;
            const centerY = handRightRect.top + handRightRect.height / 2;
            let x = e.clientX - centerX;
            let y = e.clientY - centerY;
            if (x>-150) x = -150
            if (y>-150) y = -150
            const angle = Math.atan2(y, x) * (180 / Math.PI) + 150;
            setRotationAngle(angle);
        };

        window.addEventListener('mousemove', handleMouseMove);
        return () => window.removeEventListener('mousemove', handleMouseMove);
    }, []);

    return (
        <div
            className={classNames(styles.root, styles.handImg, className)}
            style={{
                transform: `rotate(${rotationAngle}deg)` }}
        >
            <img src={handImage} alt="" />
        </div>
    );
};
