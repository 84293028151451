import styles from './back-button.module.scss';
import classNames from 'classnames';
import { setContentVisibility } from '../../redux/contentSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/rootReducer';


export interface BackButtonProps {
    className?: string;
}

export const BackButton = ({ className }: BackButtonProps) => {
    const isVisible = useSelector((state: RootState) => state.content.isVisible);
    const dispatch = useDispatch();

    const handleBackClick = () => {
        dispatch(setContentVisibility(false));
    };
    return (
        <div className={classNames(styles.root, className,{ [styles.visible]: isVisible })} onClick={handleBackClick}>
            <svg width="40" height="40" className={styles['back-button']}>
                <circle cx="20" cy="20 " r="18" className={classNames(styles.circle)} />
                <polyline
                    className={styles['arrow']}
                    points="7,20 20,12 20,18 32,18 32,22 20,22 20,28"
                />
            </svg>
        </div>
    );
};
